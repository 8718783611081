.about_us_text {
  width: 550px;
}

.about_us_container {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
}

.customer_name_logo {
  font-size: 20px;
  color: #283371;
  margin-bottom: 20px;
  padding-top: 20px;
}

.company_picture {
  max-width: 550px;
}

.about_us h2 {
  font-size: 20px;
  color: #283371;
}

.about_us p {
  line-height: 1.7;
}

.about_us a {
  text-decoration: none;
  color: #283371;
}

.logo_container {
  padding-top: 20px;
  margin-bottom: 30px;
}

.logo_box {
  width: 306px;
  height: 50px;
}

.logo {
  max-width: 100%;
  max-height: 100%;
  height: auto;
}

.company_link {
  color: #3f51b5;
}

@media screen and (max-width: 800px) {
  .about_us_text {
    flex-direction: column;
    width: -webkit-fill-available;
  }

  .company_picture_container {
    width: 100%;
    height: 400px;
    margin-left: 0px;
  }

  .company_picture {
    width: 100%;
  }

  .unauth_container {
    width: -webkit-fill-available;
    margin: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .text_container {
    padding: 0px 20px;
  }
}
