.message {
  width: 100%;
}

.container {
  display: flex !important;
  flex-direction: row;
  align-items: center;
}

.cancel_icon {
  cursor: "pointer";
  float: "right";
  margin-top: "5px";
  margin-left: "5px";
  width: "20px";
}
