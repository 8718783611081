.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
  background-color: #333333;
  font-size: 12px;
  align-items: center;
  z-index: 20000000;
}

.footerText {
  padding-left: 30px;
}

.footerText p {
  color: #ffffff;
  font-size: 12px;
}

.footer li {
  padding: 0px 10px;
  cursor: pointer;
}

.footerLinksContainer {
  display: flex;
  flex-direction: row;
  padding-right: 30px;
}

.footerLinksContainer a {
  text-decoration: none;
  color: #ffffff;
}

@media screen and (max-width: 500px) {
  .footer {
    display: none;
  }
}
