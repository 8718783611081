body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

html {
  background-color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

@font-face {
  font-family: "Open Sans Regular";
  src: url("./@_assets/fonts/OpenSans-Regular.ttf");
}
@font-face {
  font-family: "Open Sans SemiBold";
  src: url("./@_assets/fonts/OpenSans-SemiBold.ttf");
}

@font-face {
  font-family: "Open Sans Light";
  src: url("./@_assets/fonts/OpenSans-Light.ttf");
}

h2 {
  font-family: "Open Sans Light";
  color: #20272b;
}

h3 {
  font-family: "Open Sans Light";
  color: #20272b;
  font-size: 20px;
}

button {
  font-family: "Open Sans Regular";
  font-size: 16px;
  border: none;
  cursor: pointer;
}

p {
  font-family: "Open Sans Regular";
  color: #333333;
  font-size: 14px;
  line-height: 1.714;
}

li {
  font-family: "Open Sans Regular";
}

a {
  font-family: "Open Sans Regular";
}

a:hover {
  text-decoration: underline;
}

a:active {
  color: #696969;
}

strong {
  font-family: "Open Sans SemiBold";
}

/* to remove scrollbar in windows */
html {
  margin: 0;
  padding: 0;
  overflow: visible;
  overflow-x: hidden;
  /* background-color: #f0f0f0; */
}
::-webkit-scrollbar {
  width: 0px !important;
  display: none !important;
  /* remove scrollbar space */
  /* background: black;   */
  /* optional: just make scrollbar invisible */
}
/* optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  /* background: #FF0000; */
  display: none !important;
}
/* to remove scrollbar in windows */

* {
  /* overflow: auto; */
  scrollbar-width: none !important; /* Firefox 64 */
}
