.header {
  width: 100%;
  background-color: #fff;
  color: #283371;
  height: 80px;
  border-bottom: 1px solid #eceff0;
}

.header_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.logos {
  margin-left: 20px;
  text-decoration: none;
}

.logos a {
  text-decoration: none !important;
}

.logo_box {
  height: 60px;
  max-width: 500px;
  display: flex;
  align-items: center;
}

.logo_link {
  height: 100%;
  display: flex;
  align-items: center;
}

.logo {
  max-height: 100%;
  max-width: 500px;
}

.customer_name_logo {
  font-size: 20px;
  color: #283371;
  margin-bottom: 20px;
}

.links {
  list-style: none;
  display: flex;
}

.links li {
  padding: 10px;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #333333;
}

.contact_box {
  border: 1px solid #283371;
  border-radius: 2px;
  padding: 0px 9px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-left: 10px;
  color: #283371;
}

.drawer {
  display: none;
}
.contact_box_unauth {
  border: 1px solid #283371;
  border-radius: 2px;
  padding: 0px 9px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 70px;
  margin-left: 10px;
  color: #283371;
}

.contact_box_unauth:hover {
  box-shadow: 2px 8px 8px #00000029;
}

.contact_box a {
  color: #283371;
}

.contact_box:hover {
  box-shadow: 2px 8px 8px #00000029;
}

.contact_box a:hover {
  text-decoration: underline;
}

.nav_bar {
  /* display: flex;
  align-items: center; */
  margin-right: 70px;
}

.logout:hover {
  text-decoration: underline;
}

.langSelector {
  padding: 0;
}

.langSelectorContainer {
  display: none;
}

/* remove navigation in header */
@media screen and (max-width: 500px) {
  .langSelectorContainer {
    display: flex;
    width: auto;
  }

  .header {
    width: -webkit-fill-available;
  }

  .nav_bar {
    display: flex;
    align-items: center;
    margin-right: 0;
    margin-left: 30px;
  }

  .logo_box {
    width: 95px;
  }

  .logo {
    max-width: 100%;
  }

  .links {
    display: none;
  }

  .drawer {
    display: flex;
  }
}
