.privacy_policy {
  display: flexbox;
  width: 1200px;
  margin: 30px auto 70px auto;
  padding-bottom: 70px;
}

.privacy_policy_content p {
  margin: 20px 0px;
}

.privacy_policy_content a {
  text-decoration: underline;
}

.privacy_policy_header {
  color: #283371;
  font-family: Arial;
  font-size: 20px;
}

.privacy_policy h3 {
  margin: 20px 0px;
  font-size: 16px;
}

.privacy_policy_bold {
  font-family: "Open Sans SemiBold";
}

@media screen and (max-width: 500px) {
  .privacy_policy {
    width: -webkit-fill-available;
    margin: 30px auto 70px auto;
    padding-bottom: 70px;
  }
  .auth_mobile_container {
    padding: 0px 20px;
  }

  .unauth_container {
    width: -webkit-fill-available;
    margin: 0px;
    padding: 100px 20px 20px 20px;
  }
}
