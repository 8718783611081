.original_image_container {
  align-items: center;
  margin-bottom: 7px;
}

.image_box {
  width: 56px;
  height: 37px;
  background-color: #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
}

.original_image {
  max-width: 100%;
  max-height: 100%;
  height: auto;
}

.original_image_text {
  padding: 0px 15px;
}

.charge_row {
  display: flex;
  justify-content: flex-end;
}

.charge_column {
  float: left;
  width: 33.33%;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

.label {
  font-size: 9px;
  margin: 0px;
  padding-top: 5px;
  line-height: 0.3;
}

.field {
  margin: 0px;
  padding: 0px;
  font-size: 12px;
}

.image_name {
  font-size: 9px;
}
