.charge_box {
  margin-bottom: 20px;
}

.charge_row {
  border-top: 1px solid #eceff0;
  padding: 6px;
  display: flex;
  justify-content: flex-end;
}

.charge_row_total {
  padding-top: 20px;
}

.charge_row_total p {
  color: #283371;
}

.charge_column {
  float: left;
  width: 33.33%;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

.total {
  font-family: 'Open Sans SemiBold';
}

.label {
  font-size: 9px;
  margin: 0px;
  padding: 0px 3px;
  line-height: 1.4;
  align-self: center;
}

.field {
  margin: 0px;
  padding: 0px 3px;
  font-size: 12px;
  overflow: scroll;
}

.total_field {
  margin: 0px;
  padding: 0px 3px;
  font-size: 16px;
}

/* settlement discount container */
.discount_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 10px;
  font-weight: 600;
  background-color: #f8f8f8;
}

.discount_container p {
  color: #283371;
}

.discount_title {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  margin-right: 20px !important;
  margin: 0;
  margin-bottom: 10px;
  color: #283371;
}

.discount_title_text {
  margin-left: 10px;
}

.charge_row_discount {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.charge_column_discount {
  width: 33%;
  display: flex;
}

.empty_row {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  height: 10px;
}
