.link_container {
  padding-bottom: 40px;
}

.link_container li {
  padding: 20px 30px;
}

.drawer_border {
  background-color: #e4e7e8;
  width: 60%;
  height: 5px;
  display: flex;
  align-self: center;
  margin: 30px 0px;
}

.unauth_contact_link {
  color: #00000061;
}
