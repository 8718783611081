.case_info {
  display: flex;
  justify-content: center;
}

.findings_headers h2 {
  margin-bottom: 7px;
}

.findings_header {
  display: flex;
  border-bottom: 2px solid #cccccc;
  margin-bottom: 10px;
}

.findings_header h3 {
  padding-right: 10px;
}

.imprint_container {
  width: 100%;
  padding: 38px 0px;
}

.imprint_container h3 {
  border-bottom: 2px solid #cccccc;
  padding-bottom: 20px;
}

.imprint_details {
  display: flex;
}

.image_box {
  width: 253px;
  height: 171px;
  background-color: #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imprint_screenshot {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  transition: transform 0.25s ease;
  cursor: zoom-in;
}

.imprint_screenshot_mobile {
  display: none;
}

.modal_image_box {
  width: 70vw;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 1);
}

.modal_image {
  max-width: 100%;
  max-height: 100%;
  height: auto;
}

.image_box:hover {
  opacity: 0.7;
  box-shadow: 2px 8px 8px #00000029;
}

.imprint_text {
  padding-left: 35px;
  font-family: "Open Sans Regular";
  color: #333333;
  font-size: 14px;
}

.imprint_text p {
  margin: 0px;
}

.payment_message_container {
  padding: 10px;
  border-bottom: 1px solid #cccccc;
}

.payment_message_header {
  display: flex;
  color: #6bc43b;
  align-items: center;
}

.payment_message_header h2 {
  color: #6bc43b;
  margin-right: 15px;
}

.payment_failed_container {
  background-color: #f6e4e1;
  color: #f6e4e1;
  padding: 10px 20px;
}

.payment_failed_header h2 {
  font-size: 16px;
}

.upload_container {
  margin-top: 10px;
  padding: 10px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upload_container h2 {
  margin-bottom: 10px;
  font-size: 18px;
}

.upload_success_text {
  color: #283371;
  margin: 0px;
}

.upload_valid_text {
  color: #6bc43b;
  margin: 0px;
}

.upload_invalid_text {
  color: #f52f0b;
  margin: 0px;
}

.upload_filename {
  margin: 0px;
  font-size: 11px;
}

.resolve_case_btn_container_mobile {
  display: none;
}
.case_closed_text {
  display: none;
}

/* resolve case card at bottom of content */
@media screen and (max-width: 992px) {
  .case_info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 500px) {
  .case_closed_text {
    display: block;
  }
  .case_info_container {
    width: -webkit-fill-available;
  }

  .case_info_text_container {
    margin: 10px;
  }

  .findings_header {
    flex-direction: column;
    border: none;
    padding-left: 10px;
  }

  .findings_header h3 {
    margin: 3px;
  }

  .imprint_container {
    font-size: 1rem;
  }

  .imprint_container h3 {
    padding-left: 10px;
    padding-bottom: 0px;
    border: none;
  }

  .image_box {
    width: auto;
    height: 255px;
  }

  .imprint_screenshot {
    display: none;
  }

  .imprint_screenshot_mobile {
    display: block;
    max-width: 100%;
    max-height: 100%;
    height: auto;
  }

  .imprint_details {
    flex-direction: column;
  }

  .imprint_text {
    line-height: initial;
    padding: 10px 0px 0px 15px;
  }

  .resolve_case_btn_container_mobile {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    height: 87px;
    justify-content: space-evenly;
    align-items: center;
  }

  .resolve_case_btn_mobile {
    width: 80%;
    height: 48px;
    color: #ffffff;
    background-color: #283371;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 2px; */
  }

  .resolve_case_container {
    display: none;
  }

  .payment_message_container {
    text-align: center;
    padding-bottom: 20px;
    margin: 0px 10px;
  }

  .payment_message_header {
    flex-direction: column;
  }

  .payment_message_header h2 {
    margin-left: 0px;
    padding-bottom: 10px;
    text-align: center;
  }
}
