.payment_info_container {
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.payment_info_header {
  font-size: 24px;
}
.payment_info_text {
  font-size: 12px;
  margin-bottom: 30px;
}

.payment_message_header {
  display: flex;
  color: #6bc43b;
  align-items: center;
}
.original_image_box {
  max-height: 220px;
  overflow: scroll;
}

.mob_close_icon {
  visibility: hidden;
}
@media screen and (max-width: 500px) {
  .mob_close_icon {
    display: flex;
    visibility: visible;
    margin-top: 22px;
    float: right;
  }
}
