.findings_container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
}

.findings_container_mobile {
  display: none;
}

.modal_image_box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
}

.modal_image {
  max-width: 100%;
  max-height: calc(100vh - 150px);
}

.image_box {
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
}

.image {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  transition: transform 0.25s ease;
  cursor: zoom-in;
}

.image_col {
  width: 49%;
}

.image_col_finding_image {
  display: flex;
  flex-direction: column;
}

.image_box:hover {
  opacity: 0.7;
  box-shadow: 2px 8px 8px #00000029;
}

.finding_details {
  overflow: scroll;
  padding: 14px 0;
}

.finding_details p {
  margin: 0px;
}

.link {
  color: #3a7bd3;
  text-decoration: underline;
}

.valid_license {
  color: #6bc43b;
}

.no_license_container {
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  height: 36px;
  justify-content: space-between;
  margin-bottom: 10px;
}

.license_right {
  display: flex;
  align-items: center;
}

.no_license_container p {
  color: #7d7d7d;
}

.license_pending_container {
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  height: 36px;
  margin-bottom: 10px;
}

.license_pending_container p {
  color: #283371;
}

.license_confirmed_container {
  background-color: #6bc43b;
  display: flex;
  align-items: center;
  height: 36px;
  justify-content: space-between;
  margin-bottom: 10px;
}

.license_confirmed_container p {
  color: #fff;
}

.license_not_confirmed_container {
  background-color: #f52f0b;
  display: flex;
  align-items: center;
  height: 36px;
  justify-content: space-between;
  margin-bottom: 10px;
}

.license_not_confirmed_container p {
  color: #ffffff;
}

.check_circle_icon {
  color: #ffffff;
  padding: 0px 10px;
}

.clear_icon {
  color: #ffffff;
  padding: 0px 10px;
  cursor: pointer;
}

.timer_icon {
  color: #283371;
  padding: 0px 10px;
}

.publish_icon_container {
}

.publish_icon {
  color: #7d7d7d;
  padding: 5px 10px;
  background-color: #eceff0;
  cursor: pointer;
}

.receipt_icon {
  color: #7d7d7d;
  padding: 0px 10px;
}

.description_icon {
  color: #ffffff;
  padding: 0px 10px;
}

.publish_icon_license_not_confirmed {
  color: #ffffff;
  padding: 0px 10px;
  cursor: pointer;
  background-color: #ff6e6e;
}

.image_label {
  background-color: #f8f8f8;
}

.image_label p {
  color: #283371;
  margin: 0px;
  padding: 3px 8px;
}

.invalid_license {
  color: red;
  margin: 0px;
  padding: 0px;
}

@media screen and (max-width: 500px) {
  .findings_container {
    display: none;
  }

  .findings_container_mobile {
    display: block;
  }

  .license_confirmed_container {
    padding: 10px 0px;
  }

  .license_pending_container {
    padding: 10px 0px;
  }

  .license_not_confirmed_container {
    padding: 10px 0px;
  }

  .no_license_container {
    padding: 10px 0px;
  }
}
