.layout_container {
  background-color: #fff;
  width: 100%;
}

.content {
  max-width: 1200px;
  margin: 30px auto 70px auto;
  padding: 0 15px;
  background-color: #ffffff;
}

.login_content {
  width: 100%;
}

@media screen and (max-width: 500px) {
  .layout_container {
    width: -webkit-fill-available !important;
    min-width: -webkit-fill-available;
    margin: 0 15px;
  }

  .content {
    width: 100% !important;
    min-width: -webkit-fill-available;
    padding: 0px;
  }
}
