.FAQ {
  overflow: scroll;
  padding-bottom: 100px;
}

.container {
  margin: 30px auto;
  display: flex;
}

.text_container {
  min-height: 40px;
  margin-bottom: 30px;
}

.FAQ h2 {
  font-size: 20px;
  color: #283371;
}

@media screen and (max-width: 500px) {
  .auth_mobile_container {
    padding: 0px 20px;
  }

  .FAQ {
    margin: 0px;
  }

  .unauth_container {
    width: -webkit-fill-available;
    margin: 0px;
    padding: 100px 20px 20px 20px;
  }
}
