.confirmation_container {
  box-shadow: 2px 8px 8px #00000029;
  border-radius: 6px;
  border: 1px solid #eceff0;
  /* height: 500px; */
}

.confirmation {
  padding: 5px 90px;
  display: flex;
  justify-content: space-between;
}


.confirmation_header {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #6bc43b;
}

.check_circle_icon {
  color: #ffffff;
  margin: 0px 32px;
}

.payment_success_text {
  color: #ffffff;
}

.payment_details_container {
  width: 250px;
  padding-bottom: 40px;
}

.billing_col {
  border-bottom: 1px solid #e4e7e8;
}

.billing_col p {
  margin: 3px;
}

.billing_col_total {
  font-family: "Open Sans SemiBold";
}

.billing_col_total p {
  font-family: "Open Sans SemiBold";
  color: #6bc43b;
}

@media screen and (max-width: 500px) {
  .confirmation_container {
    box-shadow: none;
    border: none;
    width: -webkit-fill-available;
  }

  .confirmation {
    padding: 0px 15px 20px 15px;
    flex-direction: column;
  }

  .confirmation_header {
    justify-content: center;
  }

  .payment_success_text {
    font-size: 1.4rem;
  }

  .check_circle_icon {
    margin-left: 0px;
    margin-right: 10px;
  }

  .payment_text_container {
    margin-top: 30px;
    margin-bottom: 50px;
    text-align: center;
  }

  .payment_text h2 {
    font-size: 1.1rem;
    text-align: center;
  }

  .payment_details_container {
    width: -webkit-fill-available;
    margin-bottom: 20px;
  }

  .payment_details_container h3 {
    font-size: 1.1rem;
  }
}
