.original_image_container {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  max-width: 100%;
}

.image_box {
  width: 160px;
  max-width: 160px;
  height: 120px;
  background-color: #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
}

.original_image {
  max-width: 100%;
  max-height: 100%;
  height: auto;
}

.original_image_text {
  padding-left: 15px;
  width: 200px;
  height: 120px;
  overflow: scroll;
}

.original_image_text p {
  margin: 0px;
  font-size: 12px;
}

.file_note_container {
  margin-left: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.file_note_row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 180px;
}

.file_box {
  margin: 0px 16px;
}

.upload_btn {
  background-color: #283371 !important;
  color: #fff !important;
}

.note_input {
  width: 200px;
}

.clear_icon {
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .original_image_container {
    flex-direction: column;
    width: -webkit-fill-available;
  }

  .file_note_container {
    display: block;
    padding: 0px;
  }

  .file_note_row {
    flex-direction: column-reverse;
  }

  .original_image_text {
    padding: 20px 0px;
  }

  .image_box {
    width: -webkit-fill-available;
    max-width: -webkit-fill-available;
    height: 257px;
  }

  .upload_file_btn_wrap {
    margin-right: 0px;
    padding-top: 15px;
  }

  .note_input {
    width: -webkit-fill-available;
  }

  .upload_btn {
    background-color: #e4e7e8 !important;
    color: #283371 !important;
    width: 200px;
    border-radius: 60px !important;
    height: 25px !important;
  }
}
