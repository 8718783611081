.resolve_case_container {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  width: 359px;
  height: auto;
  padding: 0px 20px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #eceff0;
  border-radius: 3px;
  margin: 0px 0px 0px 55px;
  z-index: 2;
}

.resolve_case_header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cccccc;
}

.payment_info_header {
  font-size: 24px;
}
.payment_info_text {
  font-size: 12px;
  margin-bottom: 30px;
}

.payment_message_header {
  display: flex;
  color: #6bc43b;
  align-items: center;
}

.original_image_box {
  max-height: 220px;
  overflow: scroll;
}

.btn_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  margin-top: 50px;
}

.license_btn {
  width: 251px;
  height: 48px;
  background-color: #283371;
  border: 1px solid #283371;
  border-radius: 2px;
  color: white;
}

.license_btn:hover {
  box-shadow: 2px 8px 8px #00000029;
}

.resolve_case_btn {
  width: 230px;
  height: 48px;
  box-shadow: 2px 4px 4px #0000001f;
  border-radius: 2px;
  background-color: #6bc43b;
  color: #fff;
  border: none;
  margin-top: 10px;
}

.resolve_case_btn:hover {
  box-shadow: 2px 8px 8px #00000029;
}

.resolve_case_btn_disabled {
  width: 230px;
  height: 48px;
  box-shadow: 2px 4px 4px #0000001f;
  border-radius: 2px;
  background-color: #00000029;
  color: #ffffff;
  border: none;
  margin-top: 10px;
}

.payment_failed_message {
  color: #b33a3a;
  font-size: 0.7rem;
}

.license_modal_link {
  cursor: pointer;
  font-size: 12px;
  margin-top: 30px;
}

.license_modal_link:hover {
  text-decoration: underline;
}

.paid_case {
  display: flex;
  align-items: center;
  color: #6bc43b;
  margin-bottom: 20px;
}

.paid_case p {
  margin-left: 8px;
  color: #6bc43b;
}

@media screen and (max-width: 992px) {
  .resolve_case_container {
    margin: 0px 0px 55px 0px;
    width: auto;
    border: 0px;
    box-shadow: none;
  }
  .resolve_case_btn {
    color: #ffffff;
    background-color: #283371;
  }
}
