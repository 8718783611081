.upload_confirmation {
  margin: 40px;
}

.upload_confirmation_header {
  border-bottom: 1px solid #cccccc;
  color: #283371;
  font-size: 16px;
}

.upload_success_text {
  color: #283371;
  font-size: 36px;
}
