.license_container {
  width: 100%;
}

.license_header {
  margin: 10px 10px;
}

.license_header h2 {
  color: #283371;
}

.license_header_underline {
  border-bottom: 1px solid #cccccc;
}

.license_body {
  margin: 10px 10px;
}

.original_image_box {
  border-bottom: 1px solid #cccccc;
  padding: 15px 0px;
}

.upload_license_btn_container {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0px;
}

.upload_license_btn_wrap {
  display: flex;
  flex-direction: column;
  width: 216px;
  align-items: center;
}

.upload_license_btn {
  width: 216px;
  height: 46px;
  background-color: #283371;
  border-radius: 2px;
  color: #ffffff;
}

.upload_license_btn_disabled {
  width: 216px;
  height: 46px;
  border-radius: 2px;
  border: 1px solid #cccccc;
  color: #ffffff;
  background-color: #00000029;
}

.upload_license_error {
  color: #f52f0b;
  font-size: 12px;
  margin-top: 8px;
}

@media screen and (max-width: 500px) {
  .license_container {
    width: -webkit-fill-available;
  }

  .license_header_underline {
    border: none;
  }

  .license_header h2 {
    color: #20272b;
  }

  .original_image_box {
    border: none;
  }

  .upload_license_btn_container {
    justify-content: center;
  }

  .upload_license_btn_wrap {
    width: -webkit-fill-available;
  }

  .upload_license_btn {
    width: -webkit-fill-available;
  }
}
