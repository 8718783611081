.checkbox_container {
  display: flex;
}

.checkbox_label {
  color: #424242;
  font-size: 10px;
}

.payment_btn_container {
  display: flex;
  justify-content: center;
}

.payment_btn {
  width: 319px;
  height: 46px;
  background-color: #6bc43b;
  color: #ffffff;
  border-radius: 3px;
  font-size: 20px;
  margin-top: 10px;
}

.payment_btn:hover {
  box-shadow: 2px 8px 8px #00000029;
}

.payment_btn_disabled {
  width: 319px;
  height: 46px;
  background-color: #00000029;
  color: #ffffff;
  border-radius: 3px;
  font-size: 20px;
  margin-top: 10px;
}

.errorMessage {
  color: red;
  display: flex;
  align-items: center;
  margin: 10px 0px;
  font-size: 12px;
}

.errorIcon {
  margin-right: 15px;
}

.license_modal_link {
  display: none;
}

@media screen and (max-width: 500px) {
  .payment_btn_container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .payment_btn {
    width: 100%;
    height: 48px;
    box-shadow: 0px 2px 2px #0000003d;
  }

  .payment_btn_disabled {
    width: 100%;
    height: 48px;
    background-color: #00000029;
    font-size: 20px;
  }

  .payment {
    width: -webkit-fill-available;
  }

  .license_modal_link {
    display: block;
    cursor: pointer;
    font-size: 12px;
  }

  .license_modal_link:hover {
    text-decoration: underline;
  }
}
