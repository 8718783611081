.payment_modal {
  min-width: 500px;
  max-width: 816px;
  background-color: #ffffff;
  padding: 0px 25px;
}

.marginBottom {
  margin-bottom: 10px;
}
.pay_box {
  padding: 10px;
}

@media only screen and (max-width: 600px) {
  .payment_modal {
    min-width: 0px;
    max-width: 816px;
    background-color: #ffffff;
    padding: 0px 25px;
  }
}

.payment_container {
  display: flex;
  justify-content: space-between;
}

.payment_header {
  padding-top: 10px;
  color: #283371;
}

.payment_info_text {
  font-size: 12px;
  margin-bottom: 20px;
}

.original_image_box {
  max-height: 220px;
  overflow: scroll;
}

.charge_container {
  width: 350px;
}

.button {
  float: right;
}

.payment_header_mobile {
  display: none;
}

@media screen and (max-width: 500px) {
  .payment_modal {
    width: -webkit-fill-available;
    height: auto;
    padding: 15px;
  }

  .payment_container {
    display: block;
  }
  .payment_header_mobile {
    display: flex;
    margin: 0px;
  }
}

.actionsContainer {
  padding-bottom: 60px;
  padding-left: 15px;

  display: flex;
  flex-direction: column;
  align-content: flex-end;
  align-items: flex-end;
}

.header_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.nameWarningContainer {
  font-size: 13px;
  margin-bottom: 19px;
  margin-top: -25px;
  color: red;
}
