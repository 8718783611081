.error_page_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
}

.error_page_text {
  font-family: 'Londrina Solid';
  text-align: center;
  width: 300px;
}

h1 {
  font-size: 4rem;
}

.image_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 50px;
}

.icon_credit {
  font-size: 11px;
  color: #808080;
}

@media screen and (max-width: 500px) {
  .error_page_container {
    flex-direction: column;
    margin: 0px 30px 30px 30px;
    padding-bottom: 50px;
    text-align: center;
  }

  .error_image {
    height: 200px;
  }

  .image_container {
    margin-left: 0px;
  }
}
