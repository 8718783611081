.back_btn_container {
  width: 100%;
  height: 40px;
}

.text_icon_container {
  display: flex;
}

.back_btn_text {
  color: #283371;
  margin: 0px;
  margin-left: 7px;
}
