.stepper_container {
  margin-bottom: 20px;
}

.image_box {
  height: 255px;
  background-color: #eaeaea;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  max-width: 100%;
  max-height: 100%;
  height: auto;
}

.image_text {
  padding-left: 20px;
}

.link_container {
  display: flex;
  color: #7d7d7d;
  justify-content: space-evenly;
}

.link_container a {
  display: flex;
  color: #7d7d7d;
  font-family: 'Open Sans SemiBold';
}

.valid_license {
  color: #6bc43b;
}

.btn_text {
  font-size: 0.85rem;
}
