.login_page {
  height: 786px;
  background-color: #ffffff;
  display: flex;
  width: 100%;
}

.background_container {
  width: 50%;
}

.background_container img {
  height: 786px;
  min-height: 786px;
}

.login_page_content {
  width: 50%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 60px;
  padding-top: 40px;
}

.login_input {
  width: 381px;
  height: 57px;
  background: #ffffff;
}

.login_text_container {
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login_header_text {
  font-size: 24px;
}

.login_text {
  font-size: 16px;
  text-align: center;
}

.login_input_container {
  margin-bottom: 20px;
}

.login_btn {
  width: 380px;
  height: 55px;
  color: #ffffff;
  background-color: #283371;
  box-shadow: 0px 2px 2px #0000003d;
  margin-top: 20px;
  border-radius: 6px;
}

.login_info_text {
  max-width: 381px;
  /* height: 154px; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-size: 12px;
  padding: 10px;
  text-align: center;
}

.login_btn_disabled {
  width: 380px;
  height: 55px;
  color: #ffffff;
  background-color: rgba(34, 49, 138, 0.5);
  box-shadow: 0px 2px 2px #0000003d;
  margin-top: 20px;
  border-radius: 6px;
}

.login_btn:hover {
  box-shadow: 2px 8px 8px #00000029;
}

.login_error {
  text-align: center;
  color: #c41b1b;
  font-size: 12px;
  font-weight: bold;
}

.company_link {
  color: #283371;
}

.company_link:hover {
  text-decoration: underline;
}

/* remove background image on mobile view and tablet */
@media screen and (max-width: 992px) {
  .background_container {
    display: none;
  }

  .background_image_curve {
    display: none;
  }

  .login_page_content {
    width: 100%;
    padding-right: 0px;
    padding: 10px;
  }
}

/* mobile view */
@media screen and (max-width: 500px) {
  .background_container {
    display: none;
  }

  .background_image_curve {
    display: none;
  }

  .login_input {
    width: 90vw;
  }

  .login_btn {
    width: 90vw;
  }

  .login_btn_disabled {
    width: 90vw;
  }

  .login_page_content {
    width: 100%;
    padding: 10px;
  }
}
